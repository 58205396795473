import { pathEq, pathOr } from 'ramda';

const allowElectronicSignature = ({ userRoleIs, proposition }) => {
  const withDeclarativeAnnexe = pathEq(['data', 'declarativeAnnexe'], 'with', proposition);
  const withOutstandingAnnexe = pathEq(['data', 'outstandingAnnexe'], 'with', proposition);
  const hasLessThan20Employees = pathOr(false, ['data', 'additionalInformations', 'PREVOYANCE', 'hasLessThan20Employees'], proposition);

  if (!hasLessThan20Employees) {
    return true;
  }

  return (userRoleIs('groupe_adhesion') || !withDeclarativeAnnexe) && !withOutstandingAnnexe;
};

export default allowElectronicSignature;
